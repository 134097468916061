import { graphql } from "gatsby"
import React from "react"
import { SRLWrapper } from "simple-react-lightbox"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

type GalleryPageProps = {
  data: {
    content: {
      nodes: Array<{
        _createdAt: Date
        description: string
        image: {
          asset: {
            url: string
            title: string
          }
        }
      }>
    }
  }
}

const GalleryPage = ({ data }: GalleryPageProps) => {
  return (
    <Layout>
      <SEO title="Gallery" />
      <div className="container" style={{ flex: 1 }}>
        <h1>Gallery</h1>
        <SRLWrapper>
          <ul className="new gallery">
            {data.content.nodes
              .sort((a, b) => (a._createdAt >= b._createdAt ? -1 : 1))
              .map(({ image, description }) => {
                return (
                  <li>
                    <a href={image.asset.url} data-attribute="SRL">
                      <figure>
                        <img src={image.asset.url} />
                        <figcaption>{description}</figcaption>
                      </figure>
                    </a>
                  </li>
                )
              })}
          </ul>
        </SRLWrapper>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GalleryPageQuery {
    content: allSanityGalleryContent {
      nodes {
        _createdAt
        description
        image {
          asset {
            url
            title
          }
        }
      }
    }
  }
`

export default GalleryPage
